<template>
  <div class="relative w-48 h-fit overflow-hidden rounded-lg border">
    <div class="xata-badge-bg absolute top-0 left-0 w-full h-full z-10"></div>
    <svg width="958" height="325" viewBox="0 0 958 325" fill="none" xmlns="http://www.w3.org/2000/svg"
      class="w-full h-auto">
      <rect width="958" height="325" fill="#0D091D" />
      <path
        d="M288.321 112.189C288.295 132.707 280.073 152.375 265.46 166.867V166.865L213.62 218.274C211.824 220.057 208.906 220.061 207.222 218.173C194.619 204.039 187.615 185.759 187.638 166.768C187.662 146.249 195.885 126.581 210.498 112.09L210.499 112.091L257.861 65.1228C262.129 60.8894 269.114 60.859 272.734 65.6527C282.811 78.9956 288.341 95.3112 288.321 112.189Z"
        fill="url(#paint0_linear_15_27)" />
      <path
        d="M102.86 167.18C88.2469 152.689 80.0241 133.02 80.0001 112.502C79.9801 95.6244 85.5091 79.3088 95.5859 65.9659C99.2062 61.1722 106.191 61.2025 110.46 65.436L157.821 112.405L157.823 112.403C172.436 126.895 180.658 146.563 180.683 167.081C180.705 186.073 173.701 204.353 161.098 218.486C159.415 220.373 156.496 220.369 154.7 218.588L102.861 167.179L102.86 167.18Z"
        fill="url(#paint1_linear_15_27)" />
      <path
        d="M263.509 259.17C259.699 263.818 252.735 263.777 248.474 259.532L219.221 230.387C217.426 228.597 217.429 225.699 219.228 223.914L267.816 175.73C269.612 173.949 272.542 173.939 274.125 175.909C282.558 186.396 285.875 200.889 283.369 216.844C281.073 231.449 274.054 246.298 263.509 259.17Z"
        fill="url(#paint2_linear_15_27)" />
      <path
        d="M119.846 259.847C115.586 264.09 108.62 264.131 104.812 259.482C94.2653 246.61 87.247 231.764 84.9525 217.159C82.4458 201.202 85.7616 186.709 94.1943 176.222C95.7789 174.252 98.7082 174.262 100.504 176.043L149.092 224.229C150.891 226.012 150.894 228.912 149.099 230.699L119.846 259.847Z"
        fill="url(#paint3_linear_15_27)" />
      <path
        d="M396.09 126.148H379.696V75.7508H396.609C401.571 75.7508 405.832 76.7597 409.393 78.7776C412.954 80.779 415.682 83.6582 417.578 87.415C419.49 91.1554 420.446 95.6422 420.446 100.876C420.446 106.125 419.481 110.637 417.553 114.41C415.641 118.183 412.871 121.087 409.245 123.121C405.618 125.139 401.233 126.148 396.09 126.148ZM387.337 119.504H395.67C399.527 119.504 402.733 118.782 405.288 117.338C407.843 115.878 409.756 113.77 411.025 111.014C412.294 108.242 412.929 104.862 412.929 100.876C412.929 96.9219 412.294 93.567 411.025 90.8109C409.772 88.0548 407.901 85.9631 405.412 84.5358C402.923 83.1086 399.832 82.3949 396.139 82.3949H387.337V119.504Z"
        fill="white" />
      <path
        d="M430.004 126.148V75.7508H448.549C452.143 75.7508 455.118 76.3414 457.476 77.5226C459.833 78.6873 461.597 80.2705 462.767 82.2719C463.937 84.2569 464.523 86.4963 464.523 88.9899C464.523 91.0898 464.135 92.8615 463.36 94.3052C462.586 95.7325 461.547 96.8808 460.245 97.7503C458.959 98.6034 457.541 99.2268 455.992 99.6205V100.113C457.673 100.195 459.314 100.736 460.913 101.737C462.528 102.721 463.863 104.124 464.918 105.945C465.973 107.766 466.501 109.98 466.501 112.589C466.501 115.165 465.891 117.478 464.671 119.528C463.468 121.563 461.605 123.179 459.083 124.376C456.561 125.557 453.338 126.148 449.415 126.148H430.004ZM437.645 119.627H448.673C452.332 119.627 454.953 118.921 456.536 117.511C458.118 116.1 458.91 114.336 458.91 112.22C458.91 110.629 458.506 109.168 457.698 107.84C456.89 106.511 455.736 105.453 454.236 104.665C452.753 103.878 450.989 103.484 448.945 103.484H437.645V119.627ZM437.645 97.5535H447.882C449.596 97.5535 451.137 97.2254 452.505 96.5691C453.89 95.9129 454.986 94.9942 455.794 93.813C456.618 92.6155 457.03 91.2046 457.03 89.5805C457.03 87.497 456.297 85.7498 454.83 84.339C453.363 82.9281 451.112 82.2227 448.079 82.2227H437.645V97.5535Z"
        fill="white" />
      <path
        d="M493.511 140.322V88.3501H500.732V94.4775H501.35C501.778 93.69 502.397 92.7795 503.204 91.746C504.012 90.7124 505.133 89.8102 506.567 89.0391C508.001 88.2516 509.897 87.8579 512.254 87.8579C515.32 87.8579 518.057 88.629 520.464 90.1711C522.87 91.7132 524.758 93.9361 526.126 96.8398C527.511 99.7436 528.203 103.238 528.203 107.323C528.203 111.408 527.519 114.91 526.151 117.83C524.782 120.734 522.903 122.974 520.513 124.548C518.123 126.107 515.395 126.886 512.328 126.886C510.021 126.886 508.133 126.501 506.666 125.73C505.215 124.959 504.078 124.056 503.254 123.023C502.43 121.989 501.795 121.071 501.35 120.267H500.905V140.322H493.511ZM500.756 107.249C500.756 109.907 501.144 112.236 501.918 114.238C502.693 116.239 503.814 117.806 505.281 118.938C506.748 120.053 508.545 120.611 510.672 120.611C512.881 120.611 514.727 120.029 516.211 118.864C517.694 117.683 518.815 116.083 519.573 114.065C520.348 112.048 520.736 109.775 520.736 107.249C520.736 104.755 520.356 102.516 519.598 100.531C518.856 98.546 517.735 96.9793 516.235 95.8309C514.752 94.6825 512.897 94.1083 510.672 94.1083C508.529 94.1083 506.715 94.6579 505.232 95.7571C503.765 96.8562 502.652 98.3901 501.894 100.359C501.135 102.327 500.756 104.624 500.756 107.249Z"
        fill="white" />
      <path
        d="M552.542 126.911C548.981 126.911 545.874 126.099 543.22 124.475C540.566 122.85 538.505 120.578 537.038 117.658C535.571 114.738 534.837 111.326 534.837 107.421C534.837 103.5 535.571 100.072 537.038 97.1351C538.505 94.1986 540.566 91.9182 543.22 90.2941C545.874 88.67 548.981 87.8579 552.542 87.8579C556.102 87.8579 559.21 88.67 561.864 90.2941C564.518 91.9182 566.578 94.1986 568.045 97.1351C569.512 100.072 570.246 103.5 570.246 107.421C570.246 111.326 569.512 114.738 568.045 117.658C566.578 120.578 564.518 122.85 561.864 124.475C559.21 126.099 556.102 126.911 552.542 126.911ZM552.566 120.734C554.874 120.734 556.786 120.127 558.303 118.913C559.82 117.699 560.941 116.083 561.666 114.065C562.408 112.048 562.779 109.825 562.779 107.397C562.779 104.985 562.408 102.77 561.666 100.753C560.941 98.7182 559.82 97.0859 558.303 95.8555C556.786 94.6251 554.874 94.0099 552.566 94.0099C550.242 94.0099 548.313 94.6251 546.78 95.8555C545.264 97.0859 544.135 98.7182 543.393 100.753C542.667 102.77 542.305 104.985 542.305 107.397C542.305 109.825 542.667 112.048 543.393 114.065C544.135 116.083 545.264 117.699 546.78 118.913C548.313 120.127 550.242 120.734 552.566 120.734Z"
        fill="white" />
      <path
        d="M585.634 126.148L574.457 88.3501H582.098L589.54 116.108H589.911L597.379 88.3501H605.019L612.437 115.985H612.808L620.202 88.3501H627.842L616.69 126.148H609.149L601.434 98.8577H600.865L593.151 126.148H585.634Z"
        fill="white" />
      <path
        d="M650.129 126.911C646.387 126.911 643.164 126.115 640.46 124.524C637.773 122.916 635.696 120.66 634.229 117.757C632.779 114.836 632.053 111.416 632.053 107.495C632.053 103.623 632.779 100.211 634.229 97.2582C635.696 94.3052 637.74 92.0003 640.362 90.3433C642.999 88.6864 646.082 87.8579 649.609 87.8579C651.752 87.8579 653.829 88.2106 655.84 88.9161C657.852 89.6215 659.657 90.7288 661.256 92.2381C662.855 93.7474 664.116 95.7079 665.039 98.1194C665.962 100.515 666.423 103.427 666.423 106.855V109.464H636.232V103.952H659.179C659.179 102.016 658.783 100.301 657.992 98.8085C657.2 97.2992 656.088 96.1098 654.654 95.2403C653.236 94.3708 651.571 93.9361 649.659 93.9361C647.582 93.9361 645.768 94.4447 644.219 95.4618C642.686 96.4625 641.499 97.7749 640.658 99.3991C639.834 101.007 639.422 102.754 639.422 104.641V108.947C639.422 111.473 639.867 113.622 640.757 115.394C641.664 117.166 642.925 118.519 644.54 119.455C646.156 120.373 648.043 120.833 650.203 120.833C651.604 120.833 652.881 120.636 654.035 120.242C655.189 119.832 656.187 119.225 657.027 118.421C657.868 117.617 658.511 116.625 658.956 115.443L665.954 116.698C665.393 118.749 664.388 120.546 662.937 122.088C661.503 123.613 659.698 124.803 657.522 125.656C655.362 126.492 652.898 126.911 650.129 126.911Z"
        fill="white" />
      <path
        d="M674.684 126.148V88.3501H681.83V94.3544H682.225C682.918 92.3202 684.137 90.7206 685.885 89.5559C687.649 88.3747 689.643 87.7841 691.869 87.7841C692.33 87.7841 692.874 87.8005 693.501 87.8333C694.144 87.8661 694.646 87.9071 695.009 87.9564V94.9942C694.712 94.9122 694.185 94.822 693.426 94.7235C692.668 94.6087 691.91 94.5513 691.152 94.5513C689.404 94.5513 687.846 94.9204 686.478 95.6586C685.126 96.3805 684.055 97.3894 683.264 98.6854C682.472 99.965 682.077 101.425 682.077 103.066V126.148H674.684Z"
        fill="white" />
      <path
        d="M716.473 126.911C712.731 126.911 709.508 126.115 706.805 124.524C704.118 122.916 702.041 120.66 700.574 117.757C699.123 114.836 698.398 111.416 698.398 107.495C698.398 103.623 699.123 100.211 700.574 97.2582C702.041 94.3052 704.085 92.0003 706.706 90.3433C709.344 88.6864 712.426 87.8579 715.954 87.8579C718.097 87.8579 720.174 88.2106 722.185 88.9161C724.196 89.6215 726.001 90.7288 727.6 92.2381C729.199 93.7474 730.46 95.7079 731.383 98.1194C732.307 100.515 732.768 103.427 732.768 106.855V109.464H702.577V103.952H725.523C725.523 102.016 725.128 100.301 724.336 98.8085C723.545 97.2992 722.432 96.1098 720.998 95.2403C719.581 94.3708 717.916 93.9361 716.003 93.9361C713.926 93.9361 712.113 94.4447 710.563 95.4618C709.03 96.4625 707.844 97.7749 707.003 99.3991C706.179 101.007 705.766 102.754 705.766 104.641V108.947C705.766 111.473 706.212 113.622 707.102 115.394C708.008 117.166 709.269 118.519 710.885 119.455C712.5 120.373 714.388 120.833 716.547 120.833C717.948 120.833 719.226 120.636 720.38 120.242C721.534 119.832 722.531 119.225 723.372 118.421C724.213 117.617 724.856 116.625 725.301 115.443L732.298 116.698C731.738 118.749 730.732 120.546 729.282 122.088C727.847 123.613 726.042 124.803 723.866 125.656C721.707 126.492 719.243 126.911 716.473 126.911Z"
        fill="white" />
      <path
        d="M755.246 126.886C752.18 126.886 749.444 126.107 747.037 124.548C744.647 122.974 742.767 120.734 741.399 117.83C740.047 114.91 739.372 111.408 739.372 107.323C739.372 103.238 740.056 99.7436 741.424 96.8398C742.809 93.9361 744.704 91.7132 747.111 90.1711C749.518 88.629 752.246 87.8579 755.296 87.8579C757.653 87.8579 759.549 88.2516 760.983 89.0391C762.433 89.8102 763.554 90.7124 764.346 91.746C765.153 92.7795 765.78 93.69 766.225 94.4775H766.67V75.7508H774.063V126.148H766.843V120.267H766.225C765.78 121.071 765.137 121.989 764.296 123.023C763.472 124.056 762.334 124.959 760.884 125.73C759.433 126.501 757.554 126.886 755.246 126.886ZM756.878 120.611C759.005 120.611 760.801 120.053 762.269 118.938C763.752 117.806 764.873 116.239 765.631 114.238C766.406 112.236 766.794 109.907 766.794 107.249C766.794 104.624 766.414 102.327 765.656 100.359C764.898 98.3901 763.785 96.8562 762.318 95.7571C760.851 94.6579 759.038 94.1083 756.878 94.1083C754.653 94.1083 752.798 94.6825 751.315 95.8309C749.831 96.9793 748.71 98.546 747.952 100.531C747.21 102.516 746.839 104.755 746.839 107.249C746.839 109.775 747.218 112.048 747.976 114.065C748.735 116.083 749.856 117.683 751.339 118.864C752.839 120.029 754.686 120.611 756.878 120.611Z"
        fill="white" />
      <path
        d="M803.75 126.148V75.7508H811.144V94.4775H811.589C812.017 93.69 812.636 92.7795 813.443 91.746C814.251 90.7124 815.372 89.8102 816.806 89.0391C818.24 88.2516 820.136 87.8579 822.493 87.8579C825.559 87.8579 828.296 88.629 830.703 90.1711C833.109 91.7132 834.997 93.9361 836.365 96.8398C837.75 99.7436 838.442 103.238 838.442 107.323C838.442 111.408 837.758 114.91 836.39 117.83C835.021 120.734 833.142 122.974 830.752 124.548C828.362 126.107 825.634 126.886 822.567 126.886C820.26 126.886 818.372 126.501 816.905 125.73C815.454 124.959 814.317 124.056 813.493 123.023C812.669 121.989 812.034 121.071 811.589 120.267H810.971V126.148H803.75ZM810.995 107.249C810.995 109.907 811.383 112.236 812.158 114.238C812.932 116.239 814.053 117.806 815.52 118.938C816.987 120.053 818.784 120.611 820.911 120.611C823.12 120.611 824.966 120.029 826.45 118.864C827.933 117.683 829.054 116.083 829.812 114.065C830.587 112.048 830.974 109.775 830.974 107.249C830.974 104.755 830.595 102.516 829.837 100.531C829.095 98.546 827.974 96.9793 826.474 95.8309C824.991 94.6825 823.136 94.1083 820.911 94.1083C818.768 94.1083 816.954 94.6579 815.471 95.7571C814.004 96.8562 812.891 98.3901 812.133 100.359C811.375 102.327 810.995 104.624 810.995 107.249Z"
        fill="white" />
      <path
        d="M850.182 140.322C849.078 140.322 848.072 140.232 847.166 140.051C846.259 139.887 845.583 139.707 845.138 139.51L846.919 133.481C848.27 133.842 849.474 133.998 850.529 133.949C851.584 133.899 852.515 133.506 853.323 132.768C854.147 132.029 854.872 130.823 855.499 129.15L856.414 126.64L842.517 88.3501H850.43L860.048 117.683H860.444L870.063 88.3501H878L862.348 131.193C861.623 133.161 860.7 134.826 859.579 136.188C858.458 137.566 857.122 138.6 855.573 139.289C854.023 139.978 852.227 140.322 850.182 140.322Z"
        fill="white" />
      <path
        d="M530.717 161.261L546.621 188.011H547.237L563.218 161.261H582.049L557.981 200.504L582.588 239.748H563.411L547.237 212.96H546.621L530.447 239.748H511.347L536.031 200.504L511.809 161.261H530.717Z"
        fill="white" />
      <path
        d="M609.216 240.859C605.443 240.859 602.079 240.208 599.127 238.905C596.175 237.576 593.839 235.622 592.119 233.041C590.424 230.435 589.577 227.191 589.577 223.307C589.577 220.037 590.18 217.29 591.387 215.068C592.594 212.845 594.237 211.056 596.316 209.702C598.396 208.348 600.757 207.326 603.402 206.636C606.072 205.946 608.87 205.461 611.797 205.18C615.237 204.822 618.009 204.49 620.114 204.184C622.22 203.851 623.747 203.366 624.697 202.727C625.647 202.088 626.122 201.143 626.122 199.891V199.661C626.122 197.234 625.352 195.356 623.811 194.028C622.297 192.699 620.14 192.035 617.342 192.035C614.389 192.035 612.04 192.686 610.295 193.989C608.549 195.267 607.394 196.876 606.829 198.818L591.656 197.592C592.427 194.015 593.941 190.923 596.201 188.317C598.46 185.686 601.373 183.667 604.942 182.262C608.536 180.831 612.695 180.116 617.419 180.116C620.705 180.116 623.85 180.499 626.854 181.266C629.883 182.032 632.566 183.22 634.902 184.83C637.264 186.44 639.125 188.509 640.486 191.038C641.846 193.542 642.527 196.544 642.527 200.045V239.748H626.969V231.585H626.507C625.557 233.425 624.286 235.047 622.695 236.452C621.103 237.832 619.19 238.918 616.957 239.71C614.723 240.476 612.143 240.859 609.216 240.859ZM613.915 229.592C616.328 229.592 618.459 229.12 620.307 228.174C622.155 227.203 623.606 225.9 624.659 224.265C625.711 222.63 626.237 220.778 626.237 218.708V212.461C625.724 212.794 625.018 213.1 624.119 213.381C623.247 213.637 622.258 213.879 621.154 214.109C620.05 214.314 618.946 214.505 617.842 214.684C616.739 214.838 615.737 214.978 614.839 215.106C612.913 215.387 611.232 215.834 609.794 216.447C608.356 217.06 607.24 217.891 606.444 218.938C605.648 219.96 605.25 221.238 605.25 222.771C605.25 224.993 606.059 226.692 607.676 227.868C609.319 229.017 611.399 229.592 613.915 229.592Z"
        fill="white" />
      <path
        d="M686.772 180.883V193.146H651.152V180.883H686.772ZM659.239 166.779H675.643V221.659C675.643 223.167 675.875 224.342 676.337 225.185C676.799 226.003 677.441 226.577 678.262 226.91C679.109 227.242 680.085 227.408 681.189 227.408C681.959 227.408 682.729 227.344 683.499 227.216C684.269 227.063 684.86 226.948 685.271 226.871L687.851 239.02C687.029 239.275 685.874 239.569 684.385 239.901C682.896 240.259 681.086 240.476 678.955 240.553C675.002 240.706 671.536 240.182 668.558 238.982C665.605 237.781 663.308 235.916 661.665 233.386C660.022 230.857 659.213 227.663 659.239 223.805V166.779Z"
        fill="white" />
      <path
        d="M714.383 240.859C710.609 240.859 707.246 240.208 704.293 238.905C701.341 237.576 699.005 235.622 697.285 233.041C695.59 230.435 694.743 227.191 694.743 223.307C694.743 220.037 695.346 217.29 696.553 215.068C697.76 212.845 699.403 211.056 701.482 209.702C703.562 208.348 705.924 207.326 708.568 206.636C711.238 205.946 714.036 205.461 716.963 205.18C720.403 204.822 723.176 204.49 725.281 204.184C727.386 203.851 728.913 203.366 729.863 202.727C730.813 202.088 731.288 201.143 731.288 199.891V199.661C731.288 197.234 730.518 195.356 728.978 194.028C727.463 192.699 725.306 192.035 722.508 192.035C719.556 192.035 717.207 192.686 715.461 193.989C713.715 195.267 712.56 196.876 711.995 198.818L696.823 197.592C697.593 194.015 699.107 190.923 701.367 188.317C703.626 185.686 706.54 183.667 710.108 182.262C713.702 180.831 717.861 180.116 722.585 180.116C725.871 180.116 729.016 180.499 732.02 181.266C735.049 182.032 737.732 183.22 740.068 184.83C742.43 186.44 744.291 188.509 745.652 191.038C747.012 193.542 747.693 196.544 747.693 200.045V239.748H732.135V231.585H731.673C730.723 233.425 729.452 235.047 727.861 236.452C726.269 237.832 724.356 238.918 722.123 239.71C719.889 240.476 717.309 240.859 714.383 240.859ZM719.081 229.592C721.494 229.592 723.625 229.12 725.473 228.174C727.322 227.203 728.772 225.9 729.825 224.265C730.877 222.63 731.404 220.778 731.404 218.708V212.461C730.89 212.794 730.184 213.1 729.286 213.381C728.413 213.637 727.424 213.879 726.32 214.109C725.216 214.314 724.113 214.505 723.009 214.684C721.905 214.838 720.903 214.978 720.005 215.106C718.079 215.387 716.398 215.834 714.96 216.447C713.523 217.06 712.406 217.891 711.61 218.938C710.814 219.96 710.416 221.238 710.416 222.771C710.416 224.993 711.225 226.692 712.842 227.868C714.485 229.017 716.565 229.592 719.081 229.592Z"
        fill="white" />
      <defs>
        <linearGradient id="paint0_linear_15_27" x1="52.4379" y1="61.9999" x2="52.4379" y2="265.655"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#9F87FF" />
          <stop offset="1" stop-color="#8566FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_15_27" x1="905.56" y1="61.6003" x2="905.56" y2="265.256"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#9F87FF" />
          <stop offset="1" stop-color="#8566FF" />
        </linearGradient>
        <linearGradient id="paint2_linear_15_27" x1="-325.784" y1="57.6914" x2="-325.784" y2="263.349"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#DE99F6" />
          <stop offset="1" stop-color="#D669FC" />
        </linearGradient>
        <linearGradient id="paint3_linear_15_27" x1="1283.78" y1="56.9784" x2="1283.78" y2="262.637"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#DE99F6" />
          <stop offset="1" stop-color="#D669FC" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<style scoped>
.xata-badge-bg {
  --shine-deg: 45deg;

  background-repeat: no-repeat;
  background-position:
    -100% 0,
    0 0;

  background-image: linear-gradient(var(--shine-deg),
      transparent 20%,
      transparent 40%,
      rgb(68, 68, 68, 0.2) 50%,
      rgb(68, 68, 68, 0.2) 55%,
      transparent 70%,
      transparent 100%);

  background-size:
    250% 250%,
    100% 100%;
  transition: background-position 0s ease-in-out;
}

.xata-badge-bg:hover {
  background-position:
    200%0,
    0 0;
  transition-duration: 1.5s;
}
</style>
